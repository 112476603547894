import React, { useState, useRef, useEffect } from "react";
import Transition from "../utilities/Transition.js";

const ResearchAndTutorialCarousel = () => {
  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);
  const [items] = useState([
    {
      img: "research-code.png",
      alt: "Why You Should Ditch Your In-House Training Data Tools",
      quote:
        "“ Great Product. Superhuman Support. Encord enabled us to focus on vertical challenges specific to our ML startup and not the horizontal challenge of labelling data.“",
      name: "John S. - Engineering Manager @ Iterative Health",
      role: "Read our G2 reviews ",
      team: "here",
      href: "https://www.g2.com/products/encord/reviews?utm_source=review-widget",
    },
    {
      img: "research-healthcare.png",
      alt: "Pain Relief for Doctors Labeling Data",
      quote:
        "“ What we've really liked about working with Encord is the top notch customer support - the team has been highly responsive to any questions we've had. “",
      name: "Victor R. - CEO @ Synthesia",
      role: "Read our G2 reviews ",
      team: "here",
      href: "https://www.g2.com/products/encord/reviews?utm_source=review-widget",
    },
    {
      img: "research-kcl.png",
      alt: "Novel artificial intelligence-driven software significantly shortens the time required for annotation in computer vision projects.",
      quote:
        "“ Great team, with wonderful customer focus. Solid product to support your entire datapipeline and infrastructure. It simplifies the experince of building product in the computer vision space. “",
      name: "Mikkel W. - CEO & Co-Founder @ Teton AI",
      role: "Read our G2 reviews ",
      team: "here",
      href: "https://www.g2.com/products/encord/reviews?utm_source=review-widget",
    },
    {
      img: "research-code.png",
      alt: "Label Data With Code",
      quote:
        "“ The customer support is super helpful and responsive, no matter what our query is. The tool is also pretty easy to use for employees. “",
      name: "Sergio L. - General Manager @ Flair Espresso",
      role: "Read our G2 reviews ",
      team: "here",
      href: "https://www.g2.com/products/encord/reviews?utm_source=review-widget",
    },
  ]);

  const testimonials = useRef(null);

  const stopAutorotate = () => {
    setAutorotate(null);
  };

  const heightFix = () => {
    if (testimonials.current.children[active]) {
      testimonials.current.style.height =
        testimonials.current.children[active].offsetHeight + "px";
    }
  };

  useEffect(() => {
    if (!autorotate) return;
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : (active) => active + 1);
    }, autorotateTiming);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, autorotate]);

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <section className="relative mt-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div>
          {/* Carousel area */}
          <div className="relative max-w-3xl mx-auto z-0">
            {/* Carousel */}
            <div className="relative">
              {/* Testimonials */}
              <div
                className="relative flex items-start border-2 border-gray-200 rounded bg-white z-40"
                ref={testimonials}
              >
                {items.map((item, index) => (
                  <Transition
                    key={index}
                    show={active === index}
                    appear={true}
                    className="text-center px-12 py-8 pt-10 mx-4 md:mx-0"
                    enter="transition ease-in-out duration-700 transform order-first"
                    enterStart="opacity-0 -translate-y-8"
                    enterEnd="opacity-100 scale-100"
                    leave="transition ease-in-out duration-300 transform absolute"
                    leaveStart="opacity-100 translate-y-0"
                    leaveEnd="opacity-0 translate-y-8"
                  >
                    <blockquote className="text-xl font-medium italic mb-4">
                      {item.quote}
                    </blockquote>
                    <cite className="block font-bold text-lg not-italic mb-1">
                      {item.name}
                    </cite>
                    <div className="text-gray-600">
                      <span>{item.role}</span>
                      <a
                        className="text-blue-600 hover:underline"
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.team}
                      </a>
                    </div>
                  </Transition>
                ))}
              </div>

              {/* Arrows */}
              <div className="absolute inset-0 flex items-center justify-between">
                <button
                  className="w-16 h-16 p-1 flex items-center justify-center bg-white rounded-full border border-gray-200 shadow-md hover:shadow-lg transform -translate-x-2 md:-translate-x-1/2 z-50"
                  onClick={() => {
                    setActive(active === 0 ? items.length - 1 : active - 1);
                    stopAutorotate();
                  }}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-4 h-4 fill-current text-gray-500"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                  </svg>
                </button>
                <button
                  className="w-16 h-16 p-1 flex items-center justify-center bg-white rounded-full border border-gray-200 shadow-md hover:shadow-lg transform translate-x-2 md:translate-x-1/2 z-50"
                  onClick={() => {
                    setActive(active === items.length - 1 ? 0 : active + 1);
                    stopAutorotate();
                  }}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-4 h-4 fill-current text-gray-500"
                    viewBox="0 0 16 16"
                    xmlns="https://www.w3.org/2000/svg"
                  >
                    <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResearchAndTutorialCarousel;
